
// jQuery(document).ready(function($){

//  (function($){
//     $('.dropdown-menu a.dropdown-toggle').on('click', function(e) {
//       if (!$(this).next().hasClass('show')) {
//         $(this).parents('.dropdown-menu').first().find('.show').removeClass("show");
//       }
//       var $subMenu = $(this).next(".dropdown-menu");
//       $subMenu.toggleClass('show');

//       $(this).parents('li.nav-item.dropdown.show').on('hidden.bs.dropdown', function(e) {
//         $('.dropdown-submenu .show').removeClass("show");
//       });

//       return false;
//     });
// })(jQuery)

// });

$(document).ready(function() {
          var $window = $(window);

          function checkWidth() {
              var windowsize = $window.width();
              if (windowsize < 767) {
                  $('#navbarToggler').addClass('MobileNav');
              }
          }
          // Execute on load
          checkWidth();
          // Bind event listener
          $(window).resize(checkWidth);
      });

        $('#NavBtn').click(function(){
          if ($("#navbarToggler").hasClass("MobileNav")) {
            $('#MainNav').toggleClass('Show'); 
          }
        });

        $('#CloseNav').click(function(){
          $('#MainNav').removeClass('Show');
        });

        // end

        // product
          $(document).ready(function() {
          var $window = $(window);

          function checkWidth() {
              var windowsize = $window.width();
              if (windowsize < 767) {
                  $('#filToggler').addClass('MobileNav');
              }
          }
          // Execute on load
          checkWidth();
          // Bind event listener
          $(window).resize(checkWidth);
      });

        $('#NavBtn2').click(function(){
          if ($("#filToggler").hasClass("MobileNav")) {
            $('#MainFil').toggleClass('Show'); 
          }
        });

        $('#CloseNav2').click(function(){
          $('#MainFil').removeClass('Show');
        });

        // end



$(document).ready(function() {
 // executes when HTML-Document is loaded and DOM is ready

// breakpoint and up  
$(window).resize(function(){
  if ($(window).width() >= 980){  

      // when you hover a toggle show its dropdown menu
      $(".navbar .dropdown-toggle").hover(function () {
         $(this).parent().toggleClass("show");
         $(this).parent().find(".dropdown-menu").toggleClass("show"); 
       });

        // hide the menu when the mouse leaves the dropdown
      $( ".navbar .dropdown-menu" ).mouseleave(function() {
        $(this).removeClass("show");  
      });
  
    // do something here
  } 
});  
  
  

// document ready  
});

 // menu sticky rs
        window.onscroll = function() {myFunction()};

var header = document.getElementById("myHeader");
var sticky = header.offsetTop;

function myFunction() {
  if (window.pageYOffset > sticky) {
    header.classList.add("sticky");
  } else {
    header.classList.remove("sticky");
  }
}
// menu end 
// banner
 jssor_1_slider_init = function() {

            var jssor_1_options = {
              $AutoPlay: 1,
              $SlideDuration: 800,
              $SlideEasing: $Jease$.$OutQuint,
              $ArrowNavigatorOptions: {
                $Class: $JssorArrowNavigator$
              },
              $BulletNavigatorOptions: {
                $Class: $JssorBulletNavigator$
              }
            };

            var jssor_1_slider = new $JssorSlider$("jssor_1", jssor_1_options);

            /*#region responsive code begin*/

            var MAX_WIDTH = 3000;

            function ScaleSlider() {
                var containerElement = jssor_1_slider.$Elmt.parentNode;
                var containerWidth = containerElement.clientWidth;

                if (containerWidth) {

                    var expectedWidth = Math.min(MAX_WIDTH || containerWidth, containerWidth);

                    jssor_1_slider.$ScaleWidth(expectedWidth);
                }
                else {
                    window.setTimeout(ScaleSlider, 30);
                }
            }

            ScaleSlider();

            $Jssor$.$AddEvent(window, "load", ScaleSlider);
            $Jssor$.$AddEvent(window, "resize", ScaleSlider);
            $Jssor$.$AddEvent(window, "orientationchange", ScaleSlider);
            /*#endregion responsive code end*/
        };
